export const validateEmail = (email) => {
	return /^[\D\d]+@([\D]+\.)[\D]{2,3}$/g.test(email);
	// /^[\w-\.]+@([\w]+\.)+[\w-]{2,3}$/g
};

export const validatePhoneNumber = (phone) => {
	return /^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/.test(phone);
};

export const formatPhoneNumber = (event) => {
	const keys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

	if (keys.indexOf(event.key) !== -1) {
		if (event.target.value.length === 3 || event.target.value.length === 7) {
			event.target.value = `${event.target.value}-`;
		}
	}
};

export const digitsOnly = (event) => {
	if (event.key === '-') {
		event.target.value = event.target.value.substring(0, event.target.value.length - 1);
	}

	event.target.value = event.target.value.replace(/[^0-9-]/g, '');

	return event.target.value;
};

/**
 * This function compares the browsers inner width with the requested screen and returns a boolean
 *
 * Desktop Screen Sizes
 * contact pg 1260px
 * package pg 1024px
 * general 992px
 *
 * @param {string} screenToDetect | must be 'mobile', 'tablet', 'laptop', or 'desktop'
 * @param {string} page | must be a valid page; i.e. 'contact', 'portfolio', etc.
 * @returns {boolean}
 */
export const checkScreenSize = (screenToDetect, page = null) => {
	try {
		const exceptionScreenSizes = {
			contact: {
				max: 1261,
			},
			packages: {
				max: 1024,
			},
		};

		const screenSize = {
			mobile: {
				max: 576,
			},
			tablet: {
				max: page && exceptionScreenSizes[page] ? exceptionScreenSizes[page].max : 991,
			},
			laptop: {
				max: 1199,
			},
			desktop: {
				max: 2560,
			},
		};

		if (Object.keys(screenSize).indexOf(screenToDetect) === -1) {
			throw "Invalid argument! First argument must be on of: 'mobile', 'tablet', 'laptopl, or 'desktop'.";
		}

		if (page && !exceptionScreenSizes[page]) {
			throw 'Invalid argument! Second argument must be a valid page name.';
		}

		const MAXSCREENSIZE = screenSize[screenToDetect].max;

		return window.innerWidth < MAXSCREENSIZE;
	} catch (err) {
		console.error('Error: ', err);
	}
};

export function throttle(fn, ms = 0) {
	let timer;
	return (_) => {
		if (!timer) {
			timer = setTimeout(() => {
				timer = null;
				fn.apply(this, arguments);
			}, ms);
		}
	};
}

export function debounce(fn, ms = 0) {
	let timer;
	return (_) => {
		clearTimeout(timer);
		timer = setTimeout((_) => {
			timer = null;
			fn.apply(this, arguments);
		}, ms);
	};
}

export function clamp(num, lower, upper) {
	return Math.min(Math.max(num, lower), upper);
}

export function saveToLocalStorage(key, data) {
	localStorage.setItem(key, JSON.stringify(data));
}

export function getFromLocalStorage(key) {
	return JSON.parse(localStorage.getItem(key));
}
