import React, { Fragment } from 'react';
import { DesktopQuoteTool } from './DesktopQuoteTool';
import { MobileQuoteTool } from './MobileQuoteTool';
import Checkbox from '../reusable/checkbox/Checkbox';
import { throttle, saveToLocalStorage, getFromLocalStorage } from '../util/util';
import quoteToolData from '../../assets/data/quote-tool.json';
import desktopStyles from '../../styles/DesktopQuoteTool.module.css';
import accordionStyles from '../reusable/accordion/Accordion.module.css';
import mobileStyles from '../../styles/MobileQuoteTool.module.css';

class QuoteTool extends React.Component {
	constructor(props) {
		super(props);

		let isCustom = false;

		if (this.props.history.location.state) {
			isCustom = this.props.history.location.state.custom;
		}

		this.hiddenDivRef = React.createRef();
		this.basicContentRef = React.createRef();
		this.plusContentRef = React.createRef();
		this.premiumContentRef = React.createRef();

		let quoteInfo = getFromLocalStorage('quoteInfo');
		const timeNow = new Date();

		if (quoteInfo) {
			const lastStoredTime = new Date(quoteInfo.timeNow);

			if (timeNow - lastStoredTime >= 5 * 60 * 1000 || quoteInfo.isCustom !== isCustom) {
				quoteInfo = null;
			} else {
				quoteInfo.isMobile = window.innerWidth < 992;
				quoteInfo.timeNow = timeNow;
			}
		}

		this.tempOrCustom = isCustom ? 'custom' : 'template';

		this.state = quoteInfo || {
			isMobile: window.innerWidth < 992,
			plans: {
				basic: {
					active: false,
					height: '0px',
					products: quoteToolData.categories[this.tempOrCustom].basic.products,
				},
				plus: {
					active: false,
					height: '0px',
					products: quoteToolData.categories[this.tempOrCustom].plus.products,
				},
				premium: {
					active: false,
					height: '0px',
					products: quoteToolData.categories[this.tempOrCustom].premium.products,
				},
			},
			categories: this.generateCategoriesStateObject(),
			addOns: this.generateAddOnsObject(),
			selectedAddOns: [],
			estimatedPrice: 0,
			currentSlide: 1,
			timeNow,
			isCustom,
		};

		saveToLocalStorage('quoteInfo', this.state);

		this.toggleAccordion = this.toggleAccordion.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.isAnyAccordionOpened = this.isAnyAccordionOpened.bind(this);
		this.productChangeHandler = this.productChangeHandler.bind(this);
		this.createCheckboxes = this.createCheckboxes.bind(this);
		this.generateCategoriesStateObject = this.generateCategoriesStateObject.bind(this);
		this.handleAddOnChange = this.handleAddOnChange.bind(this);
		this.getAddOnBasedOnSelection = this.getAddOnBasedOnSelection.bind(this);
		this.generateAddOnsObject = this.generateAddOnsObject.bind(this);
		this.getAddOn = this.getAddOn.bind(this);
		this.requestConsultationClickHandler = this.requestConsultationClickHandler.bind(this);
		this.getSelectedCategory = this.getSelectedCategory.bind(this);
		this.getSelectedProduct = this.getSelectedProduct.bind(this);
		this.getSelectedAddOns = this.getSelectedAddOns.bind(this);
		this.switchSlide = this.switchSlide.bind(this);
		this.resize = this.resize.bind(this);

		if (window.resizeFn) {
			window.removeEventListener('resize', window.resizeFn);
		}

		window.resizeFn = throttle(this.resize, 50);
		window.addEventListener('resize', window.resizeFn);
	}

	generateCategoriesStateObject(product) {
		const categories = Object.keys(quoteToolData.categories[this.tempOrCustom]).reduce(
			(categoriesObject, currentCategory) => {
				categoriesObject[currentCategory] = quoteToolData.categories[this.tempOrCustom][
					currentCategory
				].products.reduce((productsObject, currentProduct) => {
					productsObject[currentProduct.id] = product === currentProduct.id;
					return productsObject;
				}, {});

				return categoriesObject;
			},
			{}
		);

		return categories;
	}

	generateAddOnsObject() {
		const addOns = quoteToolData.addOns.reduce((addOnStateObj, addOn) => {
			addOnStateObj[addOn.id] = false;
			return addOnStateObj;
		}, {});

		return addOns;
	}

	productChangeHandler(product) {
		const categoriesNewState = this.generateCategoriesStateObject(product);
		const addOnsNewState = this.generateAddOnsObject();

		this.setState(
			{
				categories: categoriesNewState,
				addOns: addOnsNewState,
				selectedAddOns: [],
				currentSlide: 2,
				timeNow: new Date(),
			},
			() => {
				saveToLocalStorage('quoteInfo', this.state);
			}
		);
	}

	getAddOnBasedOnSelection() {
		const id = Object.keys(this.state.categories).reduce((productId, currentCategory) => {
			if (productId) return productId;

			return Object.keys(this.state.categories[currentCategory]).reduce((productId, currentProduct) => {
				if (productId !== '') return productId;

				return this.state.categories[currentCategory][currentProduct] ? currentProduct : '';
			}, '');
		}, '');

		const [product, category] = id.split('_');

		if (category && product) {
			const addOnIds = quoteToolData.categories[this.tempOrCustom][category].products.filter(
				(product) => product.id === id
			)[0].addOns;
			return quoteToolData.addOns.filter((addOn) => addOnIds.includes(addOn.id));
		}
	}

	handleAddOnChange(id) {
		const newAddOnState = {
			...this.state.addOns,
		};
		newAddOnState[id] = !newAddOnState[id];

		const selectedAddOns = [...this.state.selectedAddOns];

		if (newAddOnState[id]) {
			selectedAddOns.push(id);
		} else {
			const idx = selectedAddOns.indexOf(id);
			selectedAddOns.splice(idx, 1);
		}

		this.setState(
			{
				addOns: newAddOnState,
				selectedAddOns,
				timeNow: new Date(),
			},
			() => {
				saveToLocalStorage('quoteInfo', this.state);
			}
		);
	}

	getAddOn() {
		const selectedId = this.state.selectedAddOns[this.state.selectedAddOns.length - 1];
		const addOn = quoteToolData.addOns.filter((addOn) => addOn.id === selectedId)[0];

		return {
			selectedId,
			addOn,
		};
	}

	resize() {
		this.setState({
			isMobile: window.innerWidth < 992,
		});
	}

	toggleAccordion(title, isOpen) {
		const name = title.toLowerCase();
		const height = this.hiddenDivRef.current.scrollHeight;

		this.setState(
			{
				plans: {
					basic: {
						...this.state.plans.basic,
						active: name === 'basic' ? isOpen : false,
						height: name === 'basic' && isOpen ? `${height}px` : '0px',
					},
					plus: {
						...this.state.plans.plus,
						active: name === 'plus' ? isOpen : false,
						height: name === 'plus' && isOpen ? `${height}px` : '0px',
					},
					premium: {
						...this.state.plans.premium,
						active: name === 'premium' ? isOpen : false,
						height: name === 'premium' && isOpen ? `${height}px` : '0px',
					},
				},
				timeNow: new Date(),
			},
			() => {
				saveToLocalStorage('quoteInfo', this.state);
			}
		);
	}

	createCheckboxes() {
		const addOns = this.getAddOnBasedOnSelection();

		if (addOns) {
			return addOns.map((addOn) => {
				return (
					<Checkbox
						key={addOn.id}
						id={addOn.id}
						label={addOn.title}
						checked={this.state.addOns[addOn.id]}
						handleCheckboxChange={() => {
							this.handleAddOnChange(addOn.id);
						}}
					/>
				);
			});
		}
	}

	handleCheckboxChange(id) {
		const height = this.hiddenDivRef.current.scrollHeight;
		this.setState(
			{
				plans: {
					basic: {
						...this.state.plans.basic,
						active: id === 'basic',
						height: id === 'basic' ? `${height}px` : '0px',
					},
					plus: {
						...this.state.plans.plus,
						active: id === 'plus',
						height: id === 'plus' ? `${height}px` : '0px',
					},
					premium: {
						...this.state.plans.premium,
						active: id === 'premium',
						height: id === 'premium' ? `${height}px` : '0px',
					},
				},
				timeNow: new Date(),
			},
			() => {
				saveToLocalStorage('quoteInfo', this.state);
			}
		);
	}

	isAnyAccordionOpened() {
		return this.state.plans.basic.active || this.state.plans.plus.active || this.state.plans.premium.active;
	}

	changeHiddenRef(ref) {
		this.hiddenDivRef = ref;
	}

	getSelectedCategory() {
		const selectedCategory = Object.keys(this.state.categories).reduce((selectedCategory, currentCategory) => {
			const selectedProduct = Object.keys(this.state.categories[currentCategory]).filter(
				(product) => this.state.categories[currentCategory][product]
			);

			if (selectedCategory) return selectedCategory;
			return selectedProduct.length !== 0 ? currentCategory : '';
		}, '');

		return selectedCategory || null;
	}

	getSelectedProduct(category) {
		const selectedProductId = Object.keys(this.state.categories[category]).reduce(
			(selectedProductId, currentProductId) => {
				if (selectedProductId) return selectedProductId;
				return this.state.categories[category][currentProductId] ? currentProductId : '';
			},
			''
		);

		if (selectedProductId) {
			return quoteToolData.categories[this.tempOrCustom][category].products.filter(
				(product) => product.id === selectedProductId
			)[0].name;
		}

		return null;
	}

	getSelectedAddOns() {
		return this.state.selectedAddOns.map(
			(addOnId) => quoteToolData.addOns.filter((addOn) => addOn.id === addOnId)[0].title
		);
	}

	switchSlide(e, quoteToolRef) {
		if (!this.state.plans.basic.active && !this.state.plans.plus.active && !this.state.plans.premium.active) {
			return;
		}

		const slideNum = Number(e.target.getAttribute('slide'));

		Array.from(quoteToolRef.current.querySelectorAll('[slide]')).forEach((el) => {
			if (Number(el.getAttribute('slide')) === slideNum) {
				el.classList.add(mobileStyles.hidden);
			} else {
				el.classList.remove(mobileStyles.hidden);
			}
		});

		this.setState(
			{
				currentSlide: slideNum === 1 ? 2 : 1,
				timeNow: new Date(),
			},
			() => {
				saveToLocalStorage('quoteInfo', this.state);
			}
		);
	}

	requestConsultationClickHandler() {
		const category = this.getSelectedCategory();
		const product = this.getSelectedProduct(category);
		const addOns = this.getSelectedAddOns();

		// Send data to contact page to be
		this.props.history.push('contact', {
			selectedCategory: category,
			selectedProducts: product,
			selectedAddOns: addOns,
		});
	}

	render() {
		const category = this.getSelectedCategory() || 'basic';
		return (
			<Fragment>
				<div
					className={accordionStyles.accordion}
					style={{ position: 'absolute', maxHeight: '100%', left: '-999999px', width: '190px' }}
					ref={this.hiddenDivRef}
					data-category={category}
				>
					<div key={`${category}_innerContainerHiddenDiv`} className={desktopStyles.products}>
						{this.state.plans[category].products.map((product) => (
							<div key={`${product.id}_noReasonHiddenDiv`}>
								<label key={`${product.id}_labelHiddenDiv`} className={desktopStyles.productSelection}>
									{product.name}
								</label>
							</div>
						))}
					</div>
				</div>

				{!this.state.isMobile ? (
					<DesktopQuoteTool
						toggleAccordion={this.toggleAccordion}
						isAnyAccordionOpen={this.isAnyAccordionOpened}
						basic={{
							active: this.state.plans.basic.active,
							height: this.state.plans.basic.height,
							products: this.state.plans.basic.products,
							ref: this.basicContentRef,
						}}
						plus={{
							active: this.state.plans.plus.active,
							height: this.state.plans.plus.height,
							products: this.state.plans.plus.products,
							ref: this.plusContentRef,
						}}
						premium={{
							active: this.state.plans.premium.active,
							height: this.state.plans.premium.height,
							products: this.state.plans.premium.products,
							ref: this.premiumContentRef,
						}}
						categories={this.state.categories}
						addOns={this.state.addOns}
						productChangeHandler={this.productChangeHandler}
						handleAddOnChange={this.handleAddOnChange}
						getAddOnBasedOnSelection={this.getAddOnBasedOnSelection}
						createCheckboxes={this.createCheckboxes}
						getAddOn={this.getAddOn}
						requestConsultationClickHandler={this.requestConsultationClickHandler}
					></DesktopQuoteTool>
				) : (
					<MobileQuoteTool
						plans={this.state.plans}
						handleCheckboxChange={this.handleCheckboxChange}
						isAnyAccordionOpen={this.isAnyAccordionOpened}
						createCheckboxes={this.createCheckboxes}
						getAddOn={this.getAddOn}
						categories={this.state.categories}
						productChangeHandler={this.productChangeHandler}
						requestConsultationClickHandler={this.requestConsultationClickHandler}
						switchSlide={this.switchSlide}
						currentSlide={this.state.currentSlide}
					></MobileQuoteTool>
				)}
			</Fragment>
		);
	}
}

export default QuoteTool;
