import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import data from '../../assets/data/company.json';

import styles from '../../styles/company/CompanyIntro.module.css';
import '../../styles/company/CompanyIntroSwiper.css';

function CompanyIntro() {
	const mobileScreenSize = 575;
	const tabletScreenSize = 991;

	const [mobile, setMobileState] = useState(window.innerWidth <= mobileScreenSize);
	const [desktop, setDesltopState] = useState(window.innerWidth > tabletScreenSize);

	useEffect(() => {
		window.removeEventListener('resize', () => handleResize());
		window.addEventListener('resize', () => handleResize());
	});

	const handleResize = () => {
		if (window.innerWidth <= mobileScreenSize) {
			setMobileState(true);
			setDesltopState(false);
		} else if (window.innerWidth > tabletScreenSize) {
			setMobileState(false);
			setDesltopState(true);
		} else {
			setMobileState(false);
			setDesltopState(false);
		}
	};

	const content = data.companyStatements.map((data) => {
		return (
			<span key={data.type}>
				<h2>{data.title}</h2>
				<p className={styles.postitText}>{data.text.substring(0, 85)}...</p>
				<Link to={`/${data.type}`}>...more</Link>
			</span>
		);
	});

	const postits = content.map((c, i) => {
		const types = ['values', 'mission', 'vision'];

		return (
			<div
				key={`postit${i}`}
				className={`${styles.largePostit} ${styles[types[i]]}`}
				style={{
					backgroundImage: `url(${
						i === 0
							? '/images/large-postit-1.svg'
							: i === 1
							? '/images/large-postit-2.svg'
							: '/images/large-postit-3.svg'
					})`,
				}}
			>
				{c}
			</div>
		);
	});

	const mobileSlides = content.map((c, i) => {
		return (
			<SwiperSlide tag="li" key={`mobileSlide${i}`}>
				{c}
			</SwiperSlide>
		);
	});

	const tabletSlides = postits.map((pi, i) => {
		return (
			<SwiperSlide tag="li" key={`tabletSlide${i}`}>
				{pi}
			</SwiperSlide>
		);
	});

	return (
		<>
			<div className={`${styles.companyIntroContainer} company-intro-container`}>
				{desktop ? (
					<div className={styles.intro}>
						<h1>What makes us different?</h1>
					</div>
				) : (
					''
				)}
				<div className={styles.sketch}>
					<img src="/images/citysketch.jpg" alt="" />
				</div>
				<div
					className={`${styles.smallPostit} ${styles.first}`}
					style={{ backgroundImage: `url(/images/small-postit.svg)` }}
				>
					Meet the team
				</div>
				<div
					className={`${styles.smallPostit} ${styles.second}`}
					style={{ backgroundImage: `url(/images/small-postit.svg)` }}
				>
					<span className={styles.squadText}>Join the squad</span>
				</div>
				{!desktop ? (
					<>
						<div className={styles.intro}>
							<h1>What makes us different?</h1>
						</div>
						<div className={styles.mobileSeparator}></div>
						{/* <div className={styles.carousel} style={{ marginLeft: `${CarouselMarginLeft}px` }}> */}
						<div className={styles.carousel}>
							<Swiper
								id="companyIntroSlider"
								tag="section"
								wrapperTag="ul"
								slidesPerView={1}
								pagination={{
									clickable: true,
								}}
								spaceBetween={5}
								centeredSlides={true}
								slideToClickedSlide={true}
							>
								{mobile ? mobileSlides : tabletSlides}
							</Swiper>
						</div>
					</>
				) : (
					<div className={styles.content}>{postits}</div>
				)}
			</div>
		</>
	);
}

export default CompanyIntro;
