import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import styles from '../styles/StepsBeforeMeeting.module.css';
import Modal from './reusable/modal/Modal';

function StepsBeforeMeeting(props) {
	const [showConfirmationPopup, setShowConfirmationPopupState] = useState(props.desktop ? false : true);
	const [focusOnSteps, setFocusOnStepsState] = useState(false);

	const modalFocus = useRef(null);
	const pageAutoFocus = useRef(null);

	useEffect(() => {
		if (focusOnSteps || (props.desktop && props.focusOnSteps)) {
			pageAutoFocus.current.focus();
		}
	});

	return (
		<>
			<div className={`${styles.meetingSteps} ${props.desktop === 'true' ? styles.desktop : styles.mobile}`}>
				<h1 ref={pageAutoFocus} tabIndex="-1">
					3 steps before meeting
				</h1>
				<div className={styles.step}>
					<div>
						<div className={styles.circle}>1</div>
					</div>
					<div>
						Come to the meeting with 2 to 3 websites that you like and be ready to let us know what you like
						about each of the websites that you chose.
					</div>
				</div>
				<div className={styles.step}>
					<div>
						<div className={styles.circle}>2</div>
					</div>
					<div>
						Be ready to let us know a bit about your business and what you intent to use your website for,
						as it will help us in the creative process.
					</div>
				</div>
				<div className={styles.step}>
					<div>
						<div className={styles.circle}>3</div>
					</div>
					<div>Come prepared with a budget and deadline.</div>
				</div>
			</div>
			<div className={`${styles.ctaMessage} ${props.desktop === 'true' ? styles.desktop : styles.mobile}`}>
				<hr />
				<p>Here are some things that might interest you:</p>
			</div>
			<div className={`${styles.ctaBtns} ${props.desktop === 'true' ? styles.desktop : styles.mobile}`}>
				<Link to={'/packages'} className={styles.linkButton}>
					Monthly maintenance packages
				</Link>
				<Link to={'/company'} className={styles.linkButton}>
					Meet the team
				</Link>
			</div>
			{showConfirmationPopup && (
				<Modal
					desktop="false"
					closeModal={() => {
						setShowConfirmationPopupState(false);
						setFocusOnStepsState(true);
					}}
				>
					<div className={styles.modalContentContainer}>
						<img src="/images/email-sent.svg" alt="" className={styles.envelope} />
						<p ref={modalFocus}>Your request has been sent.</p>
						<div className={styles.depthLine}>
							<hr />
							<hr />
							<p>Look to hear from us within 48 hours.</p>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
}

export default StepsBeforeMeeting;
