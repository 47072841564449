import React, { useState, useEffect } from 'react';
import portfolioData from '../../assets/data/portfolios.json';
import ItemCarouselDesktop from './ItemDesktopView';
import ItemCarouselMobile from './ItemMobileView';

import styles from '../../styles/portfolio/ItemDetails.module.css';

function ItemDetails(props) {
	const [mobileView, setMobileView] = useState(false);
	const itemDetails = portfolioData.portfolios.filter((item) => item.id === props.match.params.id)[0];
	const [mobileIconSrc, setMobileIconSrc] = useState(
		window.innerWidth > 575 ? '/images/icons/phone-icon.svg' : '/images/icons/phone-icon-gray.svg'
	);
	const [desktopIconSrc, setdesktopIconSrc] = useState('/images/icons/monitor-icon.svg');

	useEffect(() => {
		window.removeEventListener('resize', () => handleResize());
		window.addEventListener('resize', () => handleResize());
	});

	const swapIcon = (view = null) => {
		if (window.innerWidth > 575) {
			setMobileIconSrc('/images/icons/phone-icon.svg');
			setdesktopIconSrc('/images/icons/monitor-icon.svg');
		} else {
			if (view === 'mobile' || (!view && mobileView)) {
				setMobileIconSrc('/images/icons/phone-icon.svg');
				setdesktopIconSrc('/images/icons/monitor-icon-gray.svg');
			} else {
				setMobileIconSrc('/images/icons/phone-icon-gray.svg');
				setdesktopIconSrc('/images/icons/monitor-icon.svg');
			}
		}
	};

	const changeView = (view) => {
		setMobileView(view === 'mobile' ? true : false);
		swapIcon(view);
	};

	const handleResize = () => {
		swapIcon();
	};

	return (
		<>
			<div className={`${styles.itemDetailsOuter} ${mobileView ? `${styles.mobile}` : ''}`}>
				<div className={styles.heading}>
					<h1>{itemDetails.title}</h1>
					<div className={styles.itemViewButtons}>
						<button
							onClick={() => changeView('mobile')}
							className={`${mobileView ? `${styles.selected}` : ''}`}
						>
							<img src={mobileIconSrc} className={styles.mobileIcon} alt="mobile icon" />
							<p>view mobile</p>
						</button>
						<button
							onClick={() => changeView('desktop')}
							className={`${!mobileView ? `${styles.selected}` : ''}`}
						>
							<img src={desktopIconSrc} className={styles.desktopIcon} alt="desktop icon" />
							<p>view desktop</p>
						</button>
					</div>
				</div>
				<div className={`${styles.itemDetailsInner} ${mobileView ? `${styles.mobile}` : ''}`}>
					<div className={styles.content}>
						<h2>Like what you see?</h2>
						<div className={styles.description}>
							<h2>
								<span className={styles.mobileH2}>Like what you see?</span> Wants something just like
								it?
							</h2>
							<div className={styles.text}>
								<div className={styles.packageDetails}>
									<div>Project category : {itemDetails.category}</div>
									<div>Project type : {itemDetails.type}</div>
								</div>
								<p>{itemDetails.description}</p>
							</div>
							<div className={styles.packageDetails}>
								<div>Project category: {itemDetails.category}</div>
								<div>Project type: {itemDetails.type}</div>
							</div>
						</div>
						{/* {!mobileView ? ( */}
						<div className={styles.consultationBtn}>
							<button>Request Consultation</button>
						</div>
						{/* ) : (
							''
						)} */}
					</div>
					{mobileView ? (
						<div className={styles.imgCarouselMobile}>
							<ItemCarouselMobile
								key={itemDetails.id}
								photos={itemDetails.photos}
								description={itemDetails.photoDescription}
							/>
						</div>
					) : (
						<div className={styles.imgCarouselDesktop}>
							<ItemCarouselDesktop
								key={itemDetails.id}
								photos={itemDetails.photos}
								description={itemDetails.photoDescription}
							/>
						</div>
					)}
				</div>
			</div>
			{/* {mobileView ? (
				<div className={styles.consultationBtnMobile}>
					<button>Request Consultation</button>
				</div>
			) : (
				''
			)} */}
		</>
	);
}

export default ItemDetails;
