/**
 * This component handles the carousel for each portfolio item displayed
 * in mobile view via the ItemDetails "view mobile" button
 */
import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from '../../styles/portfolio/ItemMobileView.module.css';
import '../../styles/portfolio/ItemMobileViewSwiper.css';

function ItemCarouselMobile(props) {
	const [width, setWidth] = useState(0);
	const [show, setShow] = useState(false); // only display the swiper once the images have loaded

	const resize = () => {
		const image = document.querySelector(`[src='/images/web/iPhone-layer1.png'`);
		const elRect = image.getBoundingClientRect();
		setWidth(elRect.width);
	};

	useEffect(() => {
		const image = document.querySelector(`[src='/images/web/iPhone-layer1.png'`);
		image.onload = () => {
			const elRect = image.getBoundingClientRect();
			setWidth(elRect.width);
			setShow(true);
		};

		window.addEventListener('resize', resize);

		return () => {
			window.removeEventListener('resize', resize);
		};
	}, []);

	const slides = props.photos.map((photo, i) => {
		const src = `https://picsum.photos/id/${i + 1}/364/788`;
		return (
			<SwiperSlide key={`slide${i}`} tag="li">
				<img src={src} alt={`mobile carousel image ${i}`} />
			</SwiperSlide>
		);
	});

	return (
		<>
			<div className={`${styles.itemCarouselMobile} item-carousel-mobile`}>
				<img src="/images/web/iPhone-notch.png" className={styles.iPhoneNotch} alt="" />
				<div className={styles.iPhoneLayer1}>
					<img src="/images/web/iPhone-layer1.png" alt="" />
				</div>
				<div className={styles.iPhoneLayer2}>
					<img src="/images/web/iPhone-layer2.png" alt="" defer />
				</div>
				<div
					style={{
						position: 'absolute',
						width: `${width - 2 * width * 0.046244568590937306}px`,
						height: `${width * 1.988826815642458}px`,
						marginTop: `${width * 0.04217838977794318}px`,
						marginBottom: `${width * 0.04217838977794318}px`,
					}}
				>
					{show ? (
						<Swiper
							id={styles.main}
							tag="section"
							wrapperTag="ul"
							slidesPerView={1}
							navigation
							pagination={{
								clickable: true,
							}}
							spaceBetween={10}
						>
							{slides}
						</Swiper>
					) : null}
				</div>
			</div>
		</>
	);
}

export default ItemCarouselMobile;
