import React, { useState, useEffect } from 'react';
import './App.css';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { checkScreenSize } from './components/util/util';

import MobileNav from './components/MobileNav.js';
import DesktopNav from './components/DesktopNav.js';
import MobileHeader from './components/MobileHeader';
import DesktopHeader from './components/DesktopHeader';
import Home from './components/Home.js';
import Contact from './components/Contact.js';
import StepsBeforeMeeting from './components/StepsBeforeMeeting.js';
import PageFlipFooter from './components/PageFlipFooter';
import CornerFooter from './components/CornerFooter';
import QuoteTool from './components/quoteTool/QuoteTool';
import MonthlyPackages from './components/MonthlyPackages';
import Portfolio from './components/portfolio/Portfolio';
import ItemDetails from './components/portfolio/ItemDetails';
import CompanyIntro from './components/company/CompanyIntro';
import OurTeam from './components/OurTeam';

import SwiperCore, { Navigation, Pagination, Thumbs, A11y } from 'swiper';
import 'swiper/swiper-bundle.css';
import './styles/Swiper.css';

import './variables.css';
SwiperCore.use([Navigation, Pagination, Thumbs, A11y]);

/* 
  Note: <Route /> should include "exact" attribute when there are multiple similar routes,
  i.e. path="/test" and path="/test/:id", path "/test" must include attribute "exact".
*/

function App() {
	const pathName = useLocation().pathname.split('/')[1];
	const history = useHistory();
	const centerPages = ['home', 'portfolio', 'quote-tool', 'company'];

	const page = pathName === 'contact' || pathName === 'packages' ? pathName : null;

	const screenToDetect = page ? 'tablet' : 'mobile';

	const [mobile, setMobileState] = useState(checkScreenSize(screenToDetect, page));

	useEffect(() => {
		window.removeEventListener('resize', () => handleResize());
		window.addEventListener('resize', () => handleResize());
	});

	const handleResize = () => {
		setMobileState(checkScreenSize(screenToDetect, page));
	};

	return (
		<div className="App">
			<header className={`App-header${pathName === 'our-team' ? ' leftAlign' : ''}`}>
				<Helmet>
					<title>101Creatives</title>
				</Helmet>

				<MobileHeader history={history} pathName={pathName} />
				<DesktopHeader mobile={mobile} />
			</header>

			<MobileNav pathName={pathName} />
			<DesktopNav pathName={pathName} />
			<div
				className={`App-content ${centerPages.indexOf(pathName) > -1 ? 'center' : ''} ${
					pathName === 'home' || pathName === 'portfolio' ? 'centerHeight' : ''
				} ${pathName === 'portfolio' ? 'portfolio' : ''} ${pathName === 'quote-tool' ? 'quote-tool' : ''}`}
			>
				<Switch>
					<Route path="/" exact>
						<Home />
						<PageFlipFooter />
					</Route>
					<Route path="/portfolio" exact>
						<Portfolio mobile={mobile} history={history} />
					</Route>
					<Route path="/portfolio/portfolio-details/:id" render={(props) => <ItemDetails {...props} />} />
					<Route path="/quote-tool" exact>
						<QuoteTool history={history} />
						{/* <PageFlipFooter /> */}
					</Route>
					<Route path="/contact">
						<Contact history={history} mobile={mobile} />
						<CornerFooter />
					</Route>
					{/* TODO: is this necessary here? Don't want user to be able to
          navigate to it. */}
					<Route path="/steps-before-meeting">
						<StepsBeforeMeeting />
					</Route>
					<Route path="/packages">
						<MonthlyPackages mobile={mobile} />
					</Route>
					<Route path="/company">
						<CompanyIntro />
					</Route>
					<Route path="/our-team">
						<OurTeam />
					</Route>
					<Route>
						<Home />
						<PageFlipFooter />
					</Route>
				</Switch>
			</div>
		</div>
	);
}

export default App;
