import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import packageData from '../assets/data/monthly-packages.json';

import styles from '../styles/MonthlyPackages.module.css';
import '../styles/MonthlyPackagesSwiper.css';

import Package from './Package';

function MonthlyPackages(props) {
	const LARGECAROUSELSIZE = 915;
	const paginationIndex = props.mobile && window.innerWidth > LARGECAROUSELSIZE ? 1 : 0;
	const pageAutoFocus = useRef(null);

	useEffect(() => {
		pageAutoFocus.current.focus();
	});

	const packages = packageData.packages.map((data) => {
		return props.mobile ? (
			<SwiperSlide key={data.id} tag="li" style={{ width: '180px' }}>
				<Package
					packageName={data.packageName}
					currency={data.currency}
					amount={data.amount.toString()}
					frequency={data.frequency}
					src="/images/package.svg"
				>
					<ul>
						{data.points.map((pt, i) => {
							return <li key={`${data.id}-carousel${i}`}>{pt}</li>;
						})}
					</ul>
				</Package>
			</SwiperSlide>
		) : (
			<Package
				key={data.id}
				packageName={data.packageName}
				currency={data.currency}
				amount={data.amount.toString()}
				frequency={data.frequency}
				src="/images/package.svg"
			>
				<ul>
					{data.points.map((pt, i) => {
						return <li key={`${data.id}-detail${i}`}>{pt}</li>;
					})}
				</ul>
			</Package>
		);
	});

	return (
		<>
			<div className={`${styles.monthlyPackages} monthly-packages`}>
				<h1 ref={pageAutoFocus} tabIndex="-1">
					Post Development Packages
				</h1>
				{props.mobile ? (
					<>
						<div className={styles.carouselPackages}>
							<Swiper
								tag="section"
								wrapperTag="ul"
								spaceBetween={60}
								slidesPerView={'auto'}
								initialSlide={paginationIndex}
								centeredSlides={true}
								slideToClickedSlide={true}
								pagination={{
									clickable: true,
								}}
								// allowSlidePrev={false}
							>
								{packages}
							</Swiper>
						</div>
					</>
				) : (
					<>
						<div className={styles.packages}>{packages}</div>
					</>
				)}
				<p className={styles.disclaimer}>Disclaimer: All packages are subject to six month terms.</p>
			</div>
		</>
	);
}

export default MonthlyPackages;
