import React from 'react';
import { Link } from 'react-router-dom';
import { validateEmail, validatePhoneNumber, formatPhoneNumber, digitsOnly } from './util/util';
import Modal from './reusable/modal/Modal';
import StepsBeforeMeeting from './StepsBeforeMeeting';
import styles from '../styles/Contact.module.css';

class Contact extends React.Component {
	constructor(props) {
		super(props);

		// Receive data from quote tool
		console.log(this.props.history.location.state);

		this.state = {
			name: '',
			phone: '',
			email: '',
			message: '',
			privacyPolicy: false,
			subscription: false,
			emptyName: null,
			emptyEmail: null,
			emptyMessage: null,
			emptyPrivacyPolicy: null,
			validPhone: null,
			validEmail: null,
			submitAttempt: null,
			mobile: this.props.mobile,
			showModal: false,
			focusOnSteps: false,
		};

		this.phoneErrorMsg = 'Please enter a valid phone number';
		this.emailErrorMsg = 'Please enter a valid email';

		this.nameFocus = React.createRef();
		this.phoneFocus = React.createRef();
		this.emailFocus = React.createRef();
		this.messageFocus = React.createRef();
		this.privacyFocus = React.createRef();
		this.modalFocus = React.createRef();
		this.pageAutoFocus = React.createRef(); // TODO: where to auto focus for this page?

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.digitsOnly = this.digitsOnly.bind(this);
	}

	/** EVENT HANDLERS **/

	handleInputChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		if (name === 'privacyPolicy') {
			this.setState({
				emptyPrivacyPolicy: !value,
				[name]: value,
			});
		} else {
			this.setState({ [name]: value }, () => {
				if (name !== 'email') {
					this.validateInput(name, value);
				}
			});
		}
	}

	digitsOnly(event) {
		const value = digitsOnly(event);
		this.setState({ phone: value });
	}

	handleSubmit(event) {
		event.preventDefault();

		if (this.validateForm()) {
			// TODO: submit form - details should be emailed to 101creatives
			if (this.state.mobile) {
				this.props.history.push('/steps-before-meeting');
			} else {
				this.setState({ showModal: true });
			}

			this.setState({
				name: '',
				phone: '',
				email: '',
				message: '',
				privacyPolicy: false,
				subscription: false,
			});
		}
	}

	/** FORM VALIDATION **/

	validateInput(inputName, value) {
		if (inputName !== 'subscription') {
			let validEmail = this.state.validEmail;
			let validPhone = this.state.validPhone;
			let empty = false; // removes the "required" error since there's a value in the input
			let name = `empty${inputName.charAt(0).toUpperCase() + inputName.slice(1)}`;

			if (!this.isEmpty(inputName)) {
				if (inputName === 'phone') {
					validPhone = validatePhoneNumber(value);
				} else if (inputName === 'email') {
					validEmail = validateEmail(value);
				}
			} else {
				if (inputName === 'phone') {
					validPhone = true;
				} else if (inputName === 'email' && this.state.submitAttempt) {
					empty = true;
				}
			}
			this.setState({ validPhone, validEmail, [name]: empty });
		}
	}

	validateForm() {
		let validity = true;
		let validPhone = true;
		let validEmail = true;

		if (this.isEmptyForm()) {
			validity = false;
		}

		if (validity && this.state.phone && !validatePhoneNumber(this.state.phone)) {
			validity = false;
			validPhone = false;
			this.phoneFocus.current.focus();
		}

		if (validity && !validateEmail(this.state.email)) {
			validity = false;
			validEmail = false;
			this.emailFocus.current.focus();
		}

		this.setState({
			validPhone,
			validEmail,
		});

		return validity;
	}

	isEmptyForm() {
		let empty = {
			name: this.isEmpty('name'),
			email: this.isEmpty('email'),
			message: this.isEmpty('message'),
			privacyPolicy: this.isEmpty('privacyPolicy'),
		};

		if (empty.name) {
			this.nameFocus.current.focus();
		} else if (empty.email) {
			this.emailFocus.current.focus();
		} else if (empty.message) {
			this.messageFocus.current.focus();
		} else if (empty.privacyPolicy) {
			this.privacyFocus.current.focus();
		}

		this.setState({
			emptyName: empty.name,
			emptyEmail: empty.email,
			emptyMessage: empty.message,
			emptyPrivacyPolicy: empty.privacyPolicy,
			submitAttempt: true, // isEmptyForm is only called when submit is clicked, if that changes this needs to be updated.
		});

		return Object.values(empty).indexOf(true) !== -1;
	}

	isEmpty(inputName) {
		return inputName === 'privacyPolicy' ? this.state.privacyPolicy !== true : this.state[inputName] === '';
	}

	hideEmailError(event) {
		if (event.key.toLowerCase() !== 'tab' && event.key.toLowerCase() !== 'shift') {
			this.setState({ validEmail: true });
		}
	}

	render() {
		return (
			<>
				<div className={styles.contactPage}>
					<div className={styles.wrapper}>
						<div className={styles.contactForm}>
							<form name="contactForm" onSubmit={this.handleSubmit} noValidate>
								<div className={styles.textInputs}>
									<div className={styles.inputContainer}>
										<label htmlFor="name" className={this.state.name ? styles.srOnly : ''}>
											Name <span className={styles.required}>*</span>
										</label>
										<input
											id="name"
											type="text"
											className={this.state.emptyName ? 'invalid' : ''}
											ref={this.nameFocus}
											name="name"
											value={this.state.name}
											onChange={this.handleInputChange}
											autoComplete="none"
										/>
										{this.state.emptyName ? (
											<p className={`${styles.error} ${styles.srOnly}`} role="alert">
												Required
											</p>
										) : (
											''
										)}
									</div>
									<div className={styles.inputContainer}>
										<label htmlFor="phone" className={this.state.phone ? styles.srOnly : ''}>
											Phone Number
										</label>
										<input
											id="phone"
											type="text"
											className={
												this.state.phone !== '' && this.state.validPhone === false
													? 'invalid'
													: ''
											}
											ref={this.phoneFocus}
											name="phone"
											value={this.state.phone}
											maxLength="12"
											pattern="\d*"
											onKeyDown={formatPhoneNumber}
											onKeyUp={this.digitsOnly}
											onChange={this.handleInputChange}
											autoComplete="none"
										/>
										{this.state.validPhone === false ? (
											<p className={`${styles.error} ${styles.srOnly}`} role="alert">
												{' '}
												{this.phoneErrorMsg}{' '}
											</p>
										) : (
											''
										)}
									</div>
								</div>
								<div className={styles.emailInput}>
									<div className={styles.inputContainer}>
										<label htmlFor="email" className={this.state.email ? styles.srOnly : ''}>
											Email Address <span className={styles.required}>*</span>
										</label>
										<input
											id="email"
											type="email"
											className={
												this.state.emptyEmail || this.state.validEmail === false
													? 'invalid'
													: ''
											}
											ref={this.emailFocus}
											name="email"
											value={this.state.email}
											onChange={this.handleInputChange}
											onKeyUp={(e) => this.hideEmailError(e)}
											onBlur={(e) => this.validateInput('email', e.target.value)}
											autoComplete="none"
										/>
										{this.state.emptyEmail ? (
											<p className={`${styles.error} ${styles.srOnly}`} role="alert">
												Required
											</p>
										) : (
											''
										)}
										{this.state.emptyEmail === false && this.state.validEmail === false ? (
											<p className={styles.error} role="alert">
												{' '}
												{this.emailErrorMsg}{' '}
											</p>
										) : (
											''
										)}
									</div>
								</div>
								<input
									type="text"
									autoComplete="on"
									style={{ display: 'none' }}
									id="google-auto-fill-hack"
								/>
								<div className={styles.textAreaContainer}>
									<label htmlFor="message" className={this.state.message ? styles.srOnly : ''}>
										Anything we should know before meeting?{' '}
										<span className={styles.required}>*</span>
									</label>
									<textarea
										id="message"
										ref={this.messageFocus}
										name="message"
										className={`${styles.textArea} ${this.state.emptyMessage ? 'invalid' : ''}`}
										value={this.state.message}
										onChange={this.handleInputChange}
									/>
									{this.state.emptyMessage ? (
										<p className={`${styles.error} ${styles.srOnly}`} role="alert">
											Required
										</p>
									) : (
										''
									)}
								</div>
								<div className={styles.endOfForm}>
									<div className={styles.mobileContainer}>
										<div className={styles.checkboxes}>
											<div>
												<label>
													<input
														type="checkbox"
														className={
															this.state.emptyPrivacyPolicy !== null &&
															this.state.emptyPrivacyPolicy
																? 'invalid'
																: ''
														}
														ref={this.privacyFocus}
														name="privacyPolicy"
														checked={this.state.privacyPolicy}
														onChange={this.handleInputChange}
													/>
													Yes, I agree to the &nbsp;
													<b>
														<Link to={'/'} className={styles.privacyPolicy}>
															privacy policy
														</Link>
													</b>
													. <span className={styles.required}>*</span>
												</label>
												{this.state.emptyPrivacyPolicy !== null &&
												this.state.emptyPrivacyPolicy ? (
													<p className={`${styles.error} ${styles.srOnly}`} role="alert">
														Required
													</p>
												) : (
													''
												)}
											</div>
											<div className={styles.checkboxContainer}>
												<label>
													<input
														name="subscription"
														type="checkbox"
														checked={this.state.subscription}
														onChange={this.handleInputChange}
													/>
													Yes, I want to get emails on awsome package discounts!
												</label>
											</div>
										</div>
										<img src="/images/web/home-page-line.svg" className={styles.separator} alt="" />
										<div className={styles.submitLine}></div>
										<input type="submit" id="submitMobile" value="submit" />
									</div>
								</div>
							</form>
						</div>
						<div className={styles.divider}></div>
						<div className={styles.meetingSteps}>
							<StepsBeforeMeeting desktop="true" focusOnSteps={this.state.focusOnSteps} />
						</div>
					</div>
					{this.state.showModal && (
						<Modal
							desktop="true"
							closeModal={() => this.setState({ showModal: false, focusOnSteps: true })}
						>
							<div className={styles.modalContentContainer}>
								<img src="/images/email-sent.svg" alt="" className={styles.envelope} />
								<p ref={this.modalFocus} tabIndex="-1">
									Success! Your request has been sent. Look to hear from us within 48 hours.
								</p>
								<div className={styles.depthLine}>
									<hr />
									<hr />
									<p>Here are some things that might interest you:</p>
								</div>
								<Link to={'/packages'} className={styles.linkButton}>
									Monthly maintenance packages
								</Link>
								<Link to={'/company'} className={styles.linkButton}>
									Meet the team
								</Link>
							</div>
						</Modal>
					)}
				</div>
			</>
		);
	}
}

export default Contact;
