import React, { useState } from 'react';
import styles from './Checkbox.module.css';

function Checkbox(props) {
	// const [checked, setChecked] = useState(false);
	const name = props.name;
	const id = props.id;
	const label = props.label;

	// function handleCheckboxChange(event) {
	//   setChecked(event.target.checked);
	// }

	return (
		<div className={styles.checkboxContainer}>
			<label htmlFor={id}>
				<input
					type="checkbox"
					id={id}
					name={name}
					checked={props.checked}
					style={{ display: 'none' }}
					onChange={props.handleCheckboxChange}
				/>
				<span className={styles.checkmark}>
					<img
						src={
							props.checked ? '/images/web/check-box-checked.svg' : '/images/web/check-box-unchecked.png'
						}
						alt=""
					/>
					<span className={styles.text}>{label}</span>
				</span>
			</label>
		</div>
	);
}

export default Checkbox;
