import React from 'react';
import { Link } from 'react-router-dom';

import styles from '../styles/PageFlipFooter.module.css';

function PageFlipFooter() {
	return (
		<>
			<footer className={styles.pageFlipFooter}>
				<div className={styles.pageFlip}>
					<img src="/images/web/page-flip.png" alt="" />
					<Link to={'/contact'}>
						<span className={styles.question}>
							Just want
							<br aria-hidden="true" />
							to ask a question
						</span>
						<span className={styles.questionMark}>?</span>
					</Link>
				</div>
			</footer>
		</>
	);
}

export default PageFlipFooter;
