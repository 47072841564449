import React, { useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';

import portfolioData from '../../assets/data/portfolios.json';
import PortfolioItemDesktop from './PortfolioItemDesktop';
import PortfolioItemMobile from './PortfolioItemMobile';

import styles from '../../styles/portfolio/Portfolio.module.css';
import '../../styles/portfolio/PortfolioSwiper.css';

function Portfolio(props) {
	const pageAutoFocus = useRef(null);

	useEffect(() => {
		pageAutoFocus.current.focus();
	});

	// const getImgSrc = (src) => {
	//   return import(`../../assets/images/${src}`);
	// };

	// JSON data
	const portfolioItems = portfolioData.portfolios.map((data) => {
		return props.mobile ? (
			<PortfolioItemMobile
				key={data.id}
				history={props.history}
				id={data.id}
				title={data.title}
				description={data.shortDescription}
				projectLink={data.projectLink}
				previewSrcs={data.mobilePreview.map((src, i) => {
					return <img src={src} alt="" key={i} />;
				})}
			></PortfolioItemMobile>
		) : (
			<SwiperSlide key={data.id} tag="li">
				<PortfolioItemDesktop
					key={data.id}
					history={props.history}
					id={data.id}
					title={data.title}
					description={data.shortDescription}
					projectLink={data.projectLink}
					previewSrcs={data.desktopPreview.map((src, i) => {
						return <img src={src} alt="" key={i} className={i === 0 || i === 3 ? 'large' : ''} />;
					})}
				></PortfolioItemDesktop>
			</SwiperSlide>
		);
	});

	return (
		<>
			<div className={styles.portfolioContainer} tabIndex="-1" ref={pageAutoFocus}>
				{/* <ul className={styles.options}>
						<li>
							<Link to={'/'} ref={pageAutoFocus}>
							Basic
							</Link>
						</li>
						<li>
							<Link to={'/'}>Plus</Link>
						</li>
						<li>
							<Link to={'/'}>Premium</Link>
						</li>
					</ul> */}
				{props.mobile ? (
					<div className={styles.mobilePortfolioItems}>{portfolioItems}</div>
				) : (
					<div className={`${styles.desktopPortfolioItems} desktop-portfolio-items`}>
						<Swiper
							tag="section"
							wrapperTag="ul"
							// spaceBetween={5}
							slidesPerView={1}
							pagination={{
								clickable: true,
							}}
							slideToClickedSlide={true}
							centeredSlides={true}
						>
							{portfolioItems}
						</Swiper>
					</div>
				)}
			</div>
		</>
	);
}

export default Portfolio;
