import React from 'react';

import styles from '../styles/Package.module.css';

function Package(props) {
	return (
		<>
			<div className={styles.flexContainer}>
				<div className={styles.packageContainer}>
					<p className={styles.packageName}>{props.packageName}</p>
					<img src={props.src} className={styles.packageImg} alt="" />
					<div className={styles.price}>
						<div className={styles.currency}>{props.currency}</div>
						<div className={styles.amount}>{props.amount}</div>
						<div className={styles.frequency}>{props.frequency}</div>
					</div>
					<div className={styles.divider}>
						<img src="/images/package-line.png" alt="" />
					</div>
					<div className={styles.packageDetails}>{props.children}</div>
					<button>Contact us</button>
				</div>
			</div>
		</>
	);
}

export default Package;
