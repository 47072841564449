import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from '../styles/MobileNav.module.css';

function MobileNav(props) {
	function preventDuplicateHistory(event, path) {
		if (props.pathName === path) {
			event.preventDefault();
		}
	}

	return (
		<nav className={styles.mobileNav}>
			<div className={styles.mobileNavSpacer}></div>
			<div className={styles.mobileNavContainer}>
				<ul>
					<li>
						<NavLink
							to="/contact"
							activeClassName={styles.selected}
							onClick={(e) => preventDuplicateHistory(e, 'contact')}
						>
							<div className={styles.tab}>
								<img
									className={`${styles.icon} ${styles.contact}`}
									src="/images/icons/contact-icon.svg"
									alt="contact us"
								/>
								<img
									className={`${styles.selectedIcon} ${styles.contact}`}
									src="/images/icons/contact-icon-selected.svg"
									alt="contact us"
								/>
							</div>
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/portfolio"
							activeClassName={styles.selected}
							onClick={(e) => preventDuplicateHistory(e, 'portfolio')}
						>
							<div className={styles.tab}>
								<img
									className={styles.icon}
									src="/images/icons/portfolio-icon.svg"
									alt="portfolio items"
								/>
								<img
									className={styles.selectedIcon}
									src="/images/icons/portfolio-icon-selected.svg"
									alt="portfolio items"
								/>
							</div>
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/home"
							activeClassName={styles.selected}
							onClick={(e) => preventDuplicateHistory(e, 'home')}
						>
							<div className={styles.tab}>
								<img className={styles.icon} src="/images/icons/home-icon.svg" alt="home" />
								<img
									className={styles.selectedIcon}
									src="/images/icons/home-icon-selected.svg"
									alt="home"
								/>
							</div>
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/packages"
							activeClassName={styles.selected}
							onClick={(e) => preventDuplicateHistory(e, 'packages')}
						>
							<div className={styles.tab}>
								<img
									className={styles.icon}
									src="/images/icons/packages-icon.svg"
									alt="monthly packages"
								/>
								<img
									className={styles.selectedIcon}
									src="/images/icons/packages-icon-selected.svg"
									alt="monthly packages"
								/>
							</div>
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/company"
							activeClassName={styles.selected}
							onClick={(e) => preventDuplicateHistory(e, 'company')}
						>
							<div className={styles.tab}>
								<img className={styles.icon} src="/images/icons/team-icon.svg" alt="our company" />
								<img
									className={styles.selectedIcon}
									src="/images/icons/team-icon-selected.svg"
									alt="our company"
								/>
							</div>
						</NavLink>
					</li>
				</ul>
			</div>
		</nav>
	);
}

export default MobileNav;
