import React, { useEffect } from 'react';
import styles from './Modal.module.css';

const FocusTrap = require('focus-trap-react');

const Modal = (props) => {
	const { closeModal } = props;

	const close = () => (
		<button
			className={`${styles.closeBtn} ${props.desktop === 'true' ? styles.desktop : styles.mobile}`}
			onClick={closeModal}
		>
			<img src="/images/icons/close-icon.svg" alt="close" />
		</button>
	);

	// Find the element to auto-focus when the modal opens
	const childProps = props.children.props.children;
	const focusIndex = childProps.findIndex((i) => i.ref !== null);

	useEffect(() => {
		if (focusIndex !== -1) {
			childProps[focusIndex].ref.current.focus();
		}
	});

	return (
		<>
			<FocusTrap>
				<div className={styles.overlay}>
					<div
						className={`${styles.content} ${props.desktop === 'true' ? styles.desktop : styles.mobile}`}
						role="alert"
					>
						{close()}
						{props.children}
					</div>
				</div>
			</FocusTrap>
		</>
	);
};

export default Modal;
