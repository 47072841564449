/**
 * This component handles the carousel for each portfolio item displayed
 * in desktop view via the ItemDetails "view desktop" button
 */
import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from '../../styles/portfolio/ItemDesktopView.module.css';
import '../../styles/portfolio/ItemDesktopViewSwiper.css';

function ItemCarouselDesktop(props) {
	const [thumbsSwiper, setThumbsSwiper] = useState(0);

	const slides = props.photos.map((photo, i) => {
		const src = `https://picsum.photos/id/${i + 1}/812/457`;

		return (
			<SwiperSlide key={`slide${i}`} tag="li">
				<img src={src} className={styles.slideImg} alt={`carousel image ${i + 1}`} />
			</SwiperSlide>
		);
	});

	const getThumbs = () => {
		let thumbs = [];

		for (let i = 0; i < props.photos.length; i++) {
			const src = `https://picsum.photos/id/${i + 1}/262/147`;

			thumbs.push(
				<SwiperSlide key={`thumb${i}`} tag="li">
					<div className={styles.thumbImgOuter}>
						<img src={src} className={styles.thumbImgInner} alt={`carousel thumbnail ${i}`} />
					</div>
					<p>{props.description[i]}</p>
				</SwiperSlide>
			);
		}

		return thumbs;
	};

	return (
		<>
			<div className={`${styles.itemCarouselDesktop} item-carousel-desktop`}>
				<div className={styles.slideContainer}>
					<Swiper
						id={styles.main}
						thumbs={{ swiper: thumbsSwiper }}
						tag="section"
						wrapperTag="ul"
						spaceBetween={5}
						slidesPerView={1}
						slideToClickedSlide={true}
						pagination={{
							clickable: true,
						}}
						onInit={(swiper) => console.log('swiper initialization', swiper)}
						onReachEnd={() => console.log('end reached')}
						style={{ height: '100%' }}
					>
						{slides}
					</Swiper>
				</div>
				<div className={styles.thumbnails}>
					<Swiper
						id={styles.thumbs}
						navigation
						spaceBetween={12}
						slidesPerView={3}
						onSwiper={setThumbsSwiper}
						watchSlidesVisibility
						watchSlidesProgress
					>
						{getThumbs()}
					</Swiper>
				</div>
			</div>
		</>
	);
}

export default ItemCarouselDesktop;
