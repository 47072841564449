import React from 'react';
import { Link } from 'react-router-dom';

import styles from '../../styles/portfolio/PortfolioItemMobile.module.css';

function PortfolioItemMobile(props) {
	const goToProject = () => {
		// route user to portfolio-details/projectName
		props.history.push(`/portfolio${props.projectLink}/${props.id}`);
	};

	return (
		<>
			<div className={styles.mobileItem}>
				<button onClick={goToProject} className={styles.projectLink}>
					<div className={styles.collage}>{props.previewSrcs}</div>
				</button>
				<div className={styles.content}>
					<div>
						<h1>{props.title}</h1>
						<p>{props.description}</p>
					</div>
				</div>
				<div className={styles.spacer}></div>
				<div className={styles.spacer}></div>
			</div>
		</>
	);
}

export default PortfolioItemMobile;
