import React from 'react';
import styles from '../styles/CornerFooter.module.css';

function CornerFooter() {
	return (
		<>
			<footer className={styles.cornerFooter}>
				<div className={styles.cornerContainer}>
					<div className={styles.greyCorner}>
						<img src="/images/web/grey-right-corner.svg" alt="" />
					</div>
				</div>
			</footer>
		</>
	);
}

export default CornerFooter;
