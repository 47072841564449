import React from 'react';
import styles from '../styles/DesktopHeader.module.css';

function DesktopHeader(props) {
	return (
		<>
			<div className={styles.container}>
				<img src="/images/logo.svg" className={styles.desktopLogo} alt="logo" />
				{!props.mobile ? <p>101 Creatives</p> : ''}
			</div>
		</>
	);
}

export default DesktopHeader;
