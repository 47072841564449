import React, { useEffect, useState } from 'react';
import styles from '../styles/MobileHeader.module.css';

function MobileHeader(props) {
	const [headerName, setHeaderName] = useState('home');

	useEffect(() => {
		if (props.pathName !== '') {
			if (props.pathName === 'steps-before-meeting') {
				setHeaderName('contact');
			} else if (props.pathName === 'packages') {
				setHeaderName('monthly packages');
			} else {
				setHeaderName(props.pathName);
			}
		} else {
			setHeaderName('home');
		}
	}, [props.pathName]);

	function goBack() {
		props.history.goBack();
	}

	const backIconElem = (
		<button className={styles.backBtn} onClick={goBack}>
			<img src="/images/mobile/return-icon.svg" className={styles.back} alt="back" />
		</button>
	);

	const logoElement = <img src="/images/logo.svg" className={styles.logo} alt="logo" />;
	const iconElement = headerName === 'home' ? logoElement : backIconElem;

	return (
		<>
			<div className={styles.container}>
				<div className={styles.mobileHeaderIcon}>{iconElement}</div>
				<div className={styles.headerTitle}>
					<h1 className={styles.header}>{headerName}</h1>
				</div>
				<div className={styles.mobileHeaderMoreInfo}>
					<button>
						<img src="/images/mobile/more-info-icon.svg" alt="more info" />
					</button>
				</div>
			</div>
			<div className={styles.spacer}></div>
		</>
	);
}

export default MobileHeader;
