import React from 'react';
import styles from '../styles/OurTeam.module.css';

import company from '../assets/data/company.json';
class OurTeam extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentMember: null,
		};

		this.barWidth = 14;
		this.distanceOfLineFromCard = 14;

		this.team = company.team;

		this.memberContainer = React.createRef(null);
		this.leftColumnRef = React.createRef(null);
		this.rightColumnRef = React.createRef(null);
		this.middleBarRef = React.createRef(null);

		// Bindings
		this.calculateMiddleBarLocation = this.calculateMiddleBarLocation.bind(this);
		this.setWidthOfBars = this.setWidthOfBars.bind(this);
		this.calculateConnectingBarLocations = this.calculateConnectingBarLocations.bind(this);
		this.barStateSetup = this.barStateSetup.bind(this);
		this.memberCardClickHandler = this.memberCardClickHandler.bind(this);
		this.backButtonHandler = this.backButtonHandler.bind(this);
		this.getMembers = this.getMembers.bind(this);
		this.getMemberCard = this.getMemberCard.bind(this);
		this.getMemberInfo = this.getMemberInfo.bind(this);

		window.addEventListener('resize', this.barStateSetup);
	}

	componentDidMount() {
		this.barStateSetup();
	}

	barStateSetup() {
		this.calculateMiddleBarLocation();
		this.setWidthOfBars();
		this.calculateConnectingBarLocations();
	}

	setWidthOfBars() {
		if (!this.memberContainer.current) return;
		const verticalBar = this.memberContainer.current.getElementsByClassName('verticalBar');
		const horizontalBars = this.memberContainer.current.getElementsByClassName('horizontalBars');
		const size = `${this.barWidth}px`;
		Array.from(verticalBar).forEach((bar) => (bar.style.width = size));
		Array.from(horizontalBars).forEach((bar) => (bar.style.height = size));
	}

	calculateMiddleBarLocation() {
		const container = this.rightColumnRef.current;
		const middleBar = this.middleBarRef.current;
		if (!container || !middleBar) return;

		const firstElement = container.children[0].querySelector('button');
		const lastElement = container.children[container.children.length - 1].querySelector('button');
		const middleOfFirstCircle = firstElement.offsetTop + firstElement.offsetHeight / 2 - this.barWidth / 2;
		const middleOfLastCircle = lastElement.offsetTop + lastElement.offsetHeight / 2 + this.barWidth / 2;

		// console.log(middleBar);
		middleBar.style.top = `${middleOfFirstCircle}px`;
		middleBar.style.height = `${middleOfLastCircle - middleOfFirstCircle}px`;
	}

	calculateConnectingBarLocations() {
		if (!this.memberContainer.current || !this.leftColumnRef.current) return;

		const lines = this.memberContainer.current.getElementsByClassName(styles.lineToCard);

		Array.from(lines).forEach((line) => {
			const parent = line.parentElement;
			const isLeft = this.leftColumnRef.current.contains(parent);
			const button = parent.querySelector('button');

			const top = button.offsetTop + button.offsetHeight / 2 - this.barWidth / 2;

			line.style.top = `${top}px`;

			if (isLeft) {
				const right = button.offsetWidth + button.offsetLeft;
				const left = right - parent.offsetLeft + this.distanceOfLineFromCard;
				line.style.left = `${left}px`;
				line.style.width = `${
					parent.offsetLeft +
					parent.offsetWidth -
					(button.offsetLeft + button.offsetWidth) -
					this.distanceOfLineFromCard
				}px`;
			} else {
				const left = parent.offsetLeft - 7;
				line.style.left = `${left}px`;
				line.style.width = `${button.offsetLeft - left - this.distanceOfLineFromCard}px`;
			}
		});
	}

	getMembers(isFounder = false) {
		return this.team
			.filter((teamMember) => teamMember.isFounder === isFounder)
			.map((teamMember) => this.getMemberCard(teamMember));
	}

	getMemberCard(memberData) {
		return (
			<div className={styles.memberCard} key={memberData.id}>
				{memberData.isFounder ? <span className={styles.title}>Founder</span> : null}
				<span className={styles.name}>{memberData.fullName}</span>
				<button className={styles.icon} id={memberData.id} onClick={this.memberCardClickHandler}>
					<img src={memberData.img} alt="" />
				</button>
				<span className={styles.position}>{memberData.position}</span>
				<div className={[styles.lineToCard, 'horizontalBars'].join(' ')}></div>
			</div>
		);
	}

	getMemberInfo() {
		const member = this.team.filter((teamMember) => teamMember.id === this.state.currentMember)[0];
		if (!member) return null;

		return (
			<React.Fragment>
				<div className={styles.memberInfo} key={`${member.id}_info`}>
					<div className={styles.memberImage} onClick={this.backButtonHandler}>
						<img src={member.img} alt="" />
					</div>
					<div className={styles.memberName}>{member.fullName}</div>
				</div>

				<div className={styles.descriptionsCutout}></div>
				<div className={styles.memberStatus}>
					{member.isFounder ? <div className={styles.memberFounder}>Founder</div> : null}
					<div className={styles.memberPosition}>{member.position}</div>
				</div>
				<div className={styles.descriptions}>
					{member.descriptions.map((description, idx) => (
						<div className={styles.descriptionText} key={idx}>
							{description}
						</div>
					))}
				</div>
			</React.Fragment>
		);
	}

	memberCardClickHandler(event) {
		const { currentTarget } = event;
		const view1 = document.querySelector('[data-page="1"]');
		const view2 = document.querySelector('[data-page="2"]');

		view1.classList.add(styles.hideView);
		view2.classList.remove(styles.hideView);
		Array.from(document.querySelectorAll(`.${styles.icon}`)).forEach((button) => {
			button.classList.remove(styles.active);
		});
		currentTarget.classList.add(styles.active);

		this.setState({
			currentMember: currentTarget.id,
		});
	}

	backButtonHandler() {
		const view1 = document.querySelector('[data-page="1"]');
		const view2 = document.querySelector('[data-page="2"]');

		view1.classList.remove(styles.hideView);
		view2.classList.add(styles.hideView);

		Array.from(document.querySelectorAll(`.${styles.icon}`)).forEach((button) => {
			button.classList.remove(styles.active);
		});

		this.setState(
			{
				currentMember: '',
			},
			() => {
				this.barStateSetup();
			}
		);
	}

	render() {
		return (
			<div className={styles.outerContainer}>
				<div className={styles.pageCutout}></div>
				<div className={styles.teamMembers} ref={this.memberContainer} data-page="1">
					<div className={styles.leftColumn} ref={this.leftColumnRef}>
						{this.getMembers(true)}
					</div>
					<div className={styles.rightColumn} ref={this.rightColumnRef}>
						{this.getMembers()}
					</div>
					<div
						className={[styles.middleConnectingLine, 'verticalBar'].join(' ')}
						ref={this.middleBarRef}
					></div>
				</div>
				<div className={[styles.memberInfoAndDescriptions, styles.hideView].join(' ')} data-page="2">
					{this.getMemberInfo()}
				</div>
				<div className={styles.socialLinks}>
					<div className={styles.icon}>
						<a href="#insta">
							<img src="/images/icons/linkedin-icon.svg" alt="" />
						</a>
					</div>
					<div className={styles.icon}>
						<a href="#twitter">
							<img src="/images/icons/twitter-icon.svg" alt="" />
						</a>
					</div>
					<div className={styles.icon}>
						<a href="#linkedin">
							<img src="/images/icons/linkedin-icon.svg" alt="" />
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default OurTeam;
