import React, { Component, createRef, Fragment } from 'react';
import Accordion from '../reusable/accordion/Accordion';
import Checkbox from '../reusable/checkbox/Checkbox';
import styles from '../../styles/MobileQuoteTool.module.css';
import Scrollable from '../reusable/scrollable/Scrollable';

export class MobileQuoteTool extends Component {
	constructor(props) {
		super(props);

		this.curvedImageRef = createRef();

		this.quoteToolRef = createRef(null);
		this.createAddOnDescription = this.createAddOnDescription.bind(this);
		this.generateCategories = this.generateCategories.bind(this);
		this.generateProducts = this.generateProducts.bind(this);
		this.getActiveCategory = this.getActiveCategory.bind(this);
	}

	createAddOnDescription() {
		const { selectedId, addOn } = this.props.getAddOn();

		if (!selectedId) {
			return (
				<>
					<div className={`${styles.description}`}>Please choose an add-on to view its description.</div>
				</>
			);
		}

		return (
			<>
				<div className={`${styles.addOnTitle}`}>{addOn.title}</div>
				<div className={`${styles.description}`}>{addOn.description}</div>
			</>
		);
	}

	getActiveCategory() {
		const categories = Object.keys(this.props.categories);

		const activeCategory = categories.reduce((activeCategory, currentCategory) => {
			if (activeCategory) return activeCategory;
			return this.props.plans[currentCategory].active ? currentCategory : '';
		}, '');

		return activeCategory || 'basic';
	}

	generateCategories() {
		const categories = Object.keys(this.props.categories);
		return (
			<div className={styles.categories}>
				{categories.map((category, index) => {
					return (
						<Fragment key={`${category}_fragment`}>
							<div key={`${category}_container`} className={styles.hiddenRadioSelection}>
								<input
									key={`${category}_input`}
									type="radio"
									name="categories"
									id={category}
									checked={this.props.plans[category].active}
									onChange={() => this.props.handleCheckboxChange(category)}
								/>
								<label key={`${category}_label`} htmlFor={category}>
									{category[0].toUpperCase() + category.slice(1)}
								</label>
							</div>
							{index !== categories.length - 1 ? (
								<div key={`${category}_dot`} className={styles.dotSeparator}></div>
							) : null}
						</Fragment>
					);
				})}
			</div>
		);
	}

	generateProducts() {
		const activeCategory = this.getActiveCategory();
		const products = this.props.plans[activeCategory].products;
		const selectedProduct = Object.keys(this.props.categories[activeCategory]).filter(
			(product) => this.props.categories[activeCategory][product] === true
		);

		if (selectedProduct.length === 0) this.props.productChangeHandler(products[0].id);

		return (
			<div className={styles.products}>
				{products.map((product, index) => {
					return (
						<Fragment key={`${product.id}_fragment`}>
							<div key={`${product.id}_container`} className={styles.hiddenRadioSelection}>
								<input
									key={`${product.id}_input`}
									type="radio"
									name="products"
									id={product.id}
									checked={this.props.categories[activeCategory][product.id]}
									onChange={() => this.props.productChangeHandler(product.id)}
								/>
								<label key={`${product.id}_label`} htmlFor={product.id}>
									{product.name}
								</label>
							</div>
							{index !== products.length - 1 ? (
								<div key={`${product.id}_dot`} className={styles.dotSeparator}></div>
							) : null}
						</Fragment>
					);
				})}
			</div>
		);
	}

	render() {
		return (
			<div className={styles.quoteTool} ref={this.quoteToolRef}>
				{this.props.currentSlide === 1 ? this.generateCategories() : this.generateProducts()}
				<div
					className={`${styles.descriptions} ${this.props.currentSlide === 1 ? '' : styles.hidden}`}
					slide="1"
				>
					<div
						className={`${styles.description} ${styles.basic} ${
							this.props.plans.basic.active ? '' : styles.hidden
						}`}
					>
						<div className={styles.detailedContent}>
							<h2 className={styles.title}>Basic</h2>
							<p className={styles.details}>
								Basic packages are designed for small business or entrepreneurs looking for an online
								presence.
								<span className={styles.highlighted}>
									{' '}
									Bloggers, online portfolios, venues, and store front owners
								</span>{' '}
								looking to start an online store.
							</p>
						</div>
						<div className={styles.priceRange}>
							<span>
								Price range from: <span className={styles.highlighted}>$2,000 - $8,000</span>
							</span>{' '}
							<span className={styles.separator}>|</span>
							<span>click and play around to get more specific pricing</span>
						</div>
					</div>
					<div
						className={`${styles.description} ${styles.plus} ${
							this.props.plans.plus.active ? '' : styles.hidden
						}`}
					>
						<div className={styles.detailedContent}>
							<h2 className={styles.title}>Plus</h2>
							<p className={styles.details}>
								Basic packages are designed for small business or entrepreneurs looking for an online
								presence.
								<span className={styles.highlighted}>
									{' '}
									Bloggers, online portfolios, venues, and store front owners
								</span>{' '}
								looking to start an online store.
							</p>
						</div>
						<div className={styles.priceRange}>
							<span>
								Price range from: <span className={styles.highlighted}>$4,000 - $15,000</span>
							</span>{' '}
							<span className={styles.separator}>|</span>
							<span>click and play around to get more specific pricing</span>
						</div>
					</div>
					<div
						className={`${styles.description} ${styles.premium} ${
							this.props.plans.premium.active ? '' : styles.hidden
						}`}
					>
						<div className={styles.detailedContent}>
							<h2 className={styles.title}>Premium</h2>
							<p className={styles.details}>
								Basic packages are designed for small business or entrepreneurs looking for an online
								presence.
								<span className={styles.highlighted}>
									{' '}
									Bloggers, online portfolios, venues, and store front owners
								</span>{' '}
								looking to start an online store.
							</p>
						</div>
						<div className={styles.priceRange}>
							<span>
								Price range from: <span className={styles.highlighted}>$20,000 - $90,000</span>
							</span>{' '}
							<span className={styles.separator}>|</span>
							<span>click and play around to get more specific pricing</span>
						</div>
					</div>
					<div
						className={`${styles.description} ${styles.noSelection} ${
							this.props.isAnyAccordionOpen() ? styles.hidden : ''
						}`}
					>
						Please select a package before continuing.
					</div>
				</div>
				<div
					className={`${styles.productsAndSelections} ${this.props.currentSlide === 2 ? '' : styles.hidden}`}
					slide="2"
				>
					<div className={styles.backButton}>
						<img src="/images/mobile/back-button-image.png" className={styles.backButtonImage} alt="" />
						<button
							className={styles.backButtonText}
							slide="2"
							onClick={(e) => this.props.switchSlide(e, this.quoteToolRef)}
						>
							Back
						</button>
					</div>
					<div className={styles.addOnWrapper}>
						<div className={styles.addOns}>
							<Scrollable
								height={window.innerWidth <= 575 ? 170 : 200}
								curvedImageRef={this.curvedImageRef}
								bottomPadding={30}
							>
								<div className="checkbox">{this.props.createCheckboxes()}</div>
							</Scrollable>
							<div className={styles.curved} ref={this.curvedImageRef}>
								<img src="/images/mobile/bottom-shadow.png" alt="" />
							</div>
						</div>
						<div className={styles.addOnDescriptions}>{this.createAddOnDescription()}</div>
						<div className={styles.quoteAndDisclaimer}>
							<div className={styles.quoteAmount}>
								<span>RANGE: </span>
								<span>$2,500 - $5,300</span>
							</div>
							<div className={styles.disclaimer}>
								prices may vary because of specific complexities and the number of pages required to be
								completed
							</div>
						</div>
						<div className={styles.cta}>
							<button
								className={`${styles.requestConsultation} ${
									this.props.currentSlide === 2 ? '' : styles.hidden
								}`}
								slide="2"
								onClick={this.props.requestConsultationClickHandler}
							>
								Request Consultation
							</button>
						</div>
					</div>
				</div>
				<div className={`${styles.nextButton} ${this.props.currentSlide === 1 ? '' : styles.hidden}`} slide="1">
					<button
						className={styles.next}
						slide="1"
						onClick={(e) => this.props.switchSlide(e, this.quoteToolRef)}
					>
						Next
					</button>
				</div>
			</div>
		);
	}
}
