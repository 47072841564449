import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import Accordion from '../reusable/accordion/Accordion';
import Scrollable from '../reusable/scrollable/Scrollable';
import styles from '../../styles/DesktopQuoteTool.module.css';
export class DesktopQuoteTool extends React.Component {
	constructor(props) {
		super(props);

		this.curvedImageRef = createRef();

		this.createAccordion = this.createAccordion.bind(this);
		this.createAddOnDescription = this.createAddOnDescription.bind(this);
		this.hasProductBeenSelected = this.hasProductBeenSelected.bind(this);
	}

	hasProductBeenSelected() {
		const val = Object.keys(this.props.categories).reduce((prevValue, curValue) => {
			return (
				prevValue ||
				Object.keys(this.props.categories[curValue]).reduce((prev, cur) => {
					return prev || this.props.categories[curValue][cur];
				}, false)
			);
		}, false);

		return val;
	}

	createAddOnDescription() {
		const { selectedId, addOn } = this.props.getAddOn();

		if (!selectedId) {
			return (
				<>
					<div className={`${styles.description}`}>Please choose an add-on to view its description.</div>
				</>
			);
		}

		return (
			<>
				<div className={`${styles.addOnTitle}`}>{addOn.title}</div>
				<div className={`${styles.description}`}>{addOn.description}</div>
			</>
		);
	}

	createAccordion(title, category) {
		return (
			<Accordion
				key={category}
				title={title}
				open={this.props[category].active}
				height={this.props[category].height}
				ref={this.props[category].ref}
				toggleAccordion={this.props.toggleAccordion}
			>
				<div key={`${category}_innerContainer`} className={styles.products}>
					{this.props[category].products.map((product) => (
						<div key={`${product.id}_noReason`}>
							<input
								type="checkbox"
								key={`${product.id}_checkbox`}
								id={product.id}
								checked={this.props.categories[category][product.id]}
								onChange={() => {}}
							/>
							<label
								key={`${product.id}_label`}
								htmlFor={product.id}
								className={styles.productSelection}
								onClick={() => this.props.productChangeHandler(product.id)}
							>
								{product.name}
							</label>
						</div>
					))}
				</div>
			</Accordion>
		);
	}

	render() {
		return (
			<div className={styles.quoteTool}>
				<div className={styles.categories}>
					{Object.keys(this.props.categories).map((category) =>
						this.createAccordion(category[0].toUpperCase() + category.slice(1), category)
					)}
				</div>

				<div className={styles.divider}></div>

				<div className={`${styles.descriptions} ${this.hasProductBeenSelected() ? styles.hidden : ''}`}>
					<div className={`${styles.description} ${styles.basic}`}>
						<div className={styles.detailedContent}>
							<h2 className={styles.title}>Basic</h2>
							<p className={styles.details}>
								Basic packages are designed for small business or entrepreneurs looking for an online
								presence.{' '}
								<span className={styles.highlighted}>
									Bloggers, online portfolios, venues, and store front owners
								</span>{' '}
								looking to start an online store.
							</p>
						</div>
						<div className={styles.priceRange}>
							<span>
								Price range from: <span className={styles.highlighted}>$2,000 - $8,000</span>
							</span>{' '}
							<span className={styles.separator}>|</span>
							<span>click and play around to get more specific pricing</span>
						</div>
					</div>
					<div className={`${styles.description} ${styles.plus}`}>
						<div className={styles.detailedContent}>
							<h2 className={styles.title}>Plus</h2>
							<p className={styles.details}>
								Basic packages are designed for small business or entrepreneurs looking for an online
								presence.
								<span className={styles.highlighted}>
									{' '}
									Bloggers, online portfolios, venues, and store front owners
								</span>{' '}
								looking to start an online store.
							</p>
						</div>
						<div className={styles.priceRange}>
							<span>
								Price range from: <span className={styles.highlighted}>$4,000 - $15,000</span>
							</span>{' '}
							<span className={styles.separator}>|</span>
							<span>click and play around to get more specific pricing</span>
						</div>
					</div>
					<div className={`${styles.description} ${styles.premium}`}>
						<div className={styles.detailedContent}>
							<h2 className={styles.title}>Premium</h2>
							<p className={styles.details}>
								Basic packages are designed for small business or entrepreneurs looking for an online
								presence.
								<span className={styles.highlighted}>
									{' '}
									Bloggers, online portfolios, venues, and store front owners
								</span>{' '}
								looking to start an online store.
							</p>
						</div>
						<div className={styles.priceRange}>
							<span>
								Price range from: <span className={styles.highlighted}>$20,000 - $90,000</span>
							</span>{' '}
							<span className={styles.separator}>|</span>
							<span>click and play around to get more specific pricing</span>
						</div>
					</div>
				</div>
				<div className={`${styles.addOnWrapper} ${this.hasProductBeenSelected() ? '' : styles.hidden}`}>
					<div className={styles.addOns}>
						<Scrollable height={300} curvedImageRef={this.curvedImageRef} bottomPadding={30}>
							<div className="checkbox">{this.props.createCheckboxes()}</div>
						</Scrollable>
						<div className={styles.curved} ref={this.curvedImageRef} style={{ display: 'none' }}>
							<img src="/images/mobile/bottom-shadow.png" alt="" />
						</div>
					</div>
					<div className={styles.quoteAmount}>
						<span>RANGE: </span>
						<span>$2,500 - $5,300</span>
					</div>
					<div className={styles.disclaimer}>
						prices may vary because of specific complexities and the number of pages required to be
						completed
					</div>
					<div className={styles.cta}>
						<img src="/images/web/request-consultation-line.png" alt="" />
						<button
							className={styles.requestConsultation}
							onClick={this.props.requestConsultationClickHandler}
						>
							<span className={styles.requestLink}>Request Consultation</span>
						</button>
					</div>
				</div>
				<div className={`${styles.divider} ${this.hasProductBeenSelected() ? '' : styles.hidden}`}></div>
				<div className={`${styles.addOnDescriptions} ${this.hasProductBeenSelected() ? '' : styles.hidden}`}>
					{this.createAddOnDescription()}
				</div>
			</div>
		);
	}
}
