import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from '../styles/DesktopNav.module.css';

function DesktopNav(props) {
	function preventDuplicateHistory(event, path) {
		if (props.pathName === path) {
			event.preventDefault();
		}
	}

	return (
		<nav className={styles.desktopNav}>
			<ul>
				<li>
					<NavLink
						to="/home"
						activeClassName={styles.selected}
						onClick={(e) => preventDuplicateHistory(e, 'home')}
					>
						<div className={styles.tab} tabIndex="-1">
							<img className={styles.icon} src="/images/icons/home-icon.svg" alt="" />
							<span className={styles.linkText}>Home</span>
							<img className={styles.underline} src="/images/web/tab-underline.svg" alt="" />
						</div>
					</NavLink>
				</li>
				<li>
					<NavLink
						to="/portfolio"
						activeClassName={styles.selected}
						onClick={(e) => preventDuplicateHistory(e, 'portfolio')}
					>
						<div className={styles.tab} tabIndex="-1">
							<img className={styles.icon} src="/images/icons/portfolio-icon.svg" alt="" />
							<span className={styles.linkText}>Portfolio</span>
							<img className={styles.underline} src="/images/web/tab-underline.svg" alt="" />
						</div>
					</NavLink>
				</li>
				<li>
					<NavLink
						to="/packages"
						activeClassName={styles.selected}
						onClick={(e) => preventDuplicateHistory(e, 'packages')}
					>
						<div className={styles.tab} tabIndex="-1">
							<img className={styles.icon} src="/images/icons/packages-icon.svg" alt="" />
							<span className={styles.linkText}>Packages</span>
							<img className={styles.underline} src="/images/web/tab-underline.svg" alt="" />
						</div>
					</NavLink>
				</li>
				<li>
					<NavLink
						to="/company"
						activeClassName={styles.selected}
						onClick={(e) => preventDuplicateHistory(e, 'company')}
					>
						<div className={styles.tab} tabIndex="-1">
							<img className={styles.icon} src="/images/icons/team-icon.svg" alt="" />
							<span className={styles.linkText}>Our Company</span>
							<img className={styles.underline} src="/images/web/tab-underline.svg" alt="" />
						</div>
					</NavLink>
				</li>
				<div className={styles.overlay}></div>
			</ul>
		</nav>
	);
}

export default DesktopNav;
