import React, { createRef } from 'react';
import styles from './Scrollable.module.css';
import { clamp } from '../../util/util';

class Scrollable extends React.Component {
	constructor(props) {
		super(props);

		this.drag = false;

		this.viewportRef = createRef();
		this.contentRef = createRef();
		this.scrollbar = createRef();
		this.scrollbarThumb = createRef();

		this.scrollHandler = this.scrollHandler.bind(this);
		this.toggleScroll = this.toggleScroll.bind(this);
		this.calculatePercentage = this.calculatePercentage.bind(this);
		this.calculateThumbHeight = this.calculateThumbHeight.bind(this);
		this.mouseDownHandler = this.mouseDownHandler.bind(this);
		this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
		this.mouseUpHandler = this.mouseUpHandler.bind(this);
		this.resizeHandler = this.resizeHandler.bind(this);

		window.addEventListener('mouseup', this.mouseUpHandler);
		window.addEventListener('mousemove', this.mouseMoveHandler);
		window.addEventListener('resize', this.resizeHandler);
	}

	componentDidMount() {
		if (this.viewportRef.current && this.contentRef.current) {
			if (this.props.height >= this.contentRef.current.scrollHeight) {
				this.toggleScroll();
			} else {
				this.toggleScroll(true);
			}

			this.calculateThumbHeight();
		}
	}

	componentDidUpdate() {
		if (this.props.height >= this.contentRef.current.scrollHeight) {
			this.toggleScroll();
		} else {
			this.toggleScroll(true);
		}

		this.calculateThumbHeight();
	}

	toggleScroll(add = false) {
		this.scrollbar.current.style.display = add ? 'flex' : 'none';
		this.viewportRef.current.style.height = add ? `${this.props.height}px` : 'auto';

		if (add) {
			this.contentRef.current.classList.remove(styles.noScroll);
			if (this.props.bottomPadding) {
				this.contentRef.current.style.paddingBottom = `${this.props.bottomPadding}px`;
			}
		} else {
			this.contentRef.current.classList.add(styles.noScroll);
			this.contentRef.current.style.paddingBottom = `0px`;
		}

		if (this.props.curvedImageRef && this.props.curvedImageRef.current) {
			this.props.curvedImageRef.current.style.display = add ? 'block' : 'none';
		} else {
			window.dispatchEvent(new Event('resize'));
		}
	}

	scrollHandler() {
		const scrollPercentage = this.calculatePercentage();
		const top = (this.scrollbar.current.clientHeight - this.scrollbarThumb.current.clientHeight) * scrollPercentage;
		this.scrollbarThumb.current.style.top = `${top}px`;
	}

	calculateThumbHeight() {
		if (this.viewportRef.current && this.contentRef.current && this.scrollbar.current) {
			const height =
				(this.viewportRef.current.clientHeight / this.contentRef.current.scrollHeight) *
				this.scrollbar.current.clientHeight;
			this.scrollbarThumb.current.style.height = `${height}px`;
		}
	}

	calculatePercentage() {
		return (
			this.contentRef.current.scrollTop /
			(this.contentRef.current.scrollHeight - this.viewportRef.current.clientHeight)
		);
	}

	mouseDownHandler(event) {
		this.drag = true;

		const scrollbarRect = this.scrollbar.current.getBoundingClientRect();
		const scrollbarThumbRect = this.scrollbarThumb.current.getBoundingClientRect();

		const initialY = event.clientY;
		const thumbLocalY = initialY - scrollbarThumbRect.top;

		this.draggingStateTop = scrollbarRect.top + thumbLocalY;
		this.draggingStateBottom = scrollbarRect.bottom - scrollbarThumbRect.height + thumbLocalY;
		this.draggingStateHeight = this.draggingStateBottom - this.draggingStateTop;
	}

	mouseMoveHandler(event) {
		if (this.drag) {
			const clientY = clamp(event.clientY, this.draggingStateTop, this.draggingStateBottom);
			const localOffset = clientY - this.draggingStateTop;
			const localOffsetPercentage = localOffset / this.draggingStateHeight;

			this.contentRef.current.scrollTop =
				localOffsetPercentage * (this.contentRef.current.scrollHeight - this.viewportRef.current.clientHeight);
		}
	}

	mouseUpHandler() {
		if (this.drag) {
			this.drag = false;
		}
	}

	resizeHandler() {
		this.calculateThumbHeight();
	}

	render() {
		return (
			<div className={styles.scrollableWrapper}>
				<div
					className={styles.scrollableViewport}
					ref={this.viewportRef}
					style={{ height: `${this.props.height || 100}px` }}
				>
					<div className={styles.scrollableContent} ref={this.contentRef} onScroll={this.scrollHandler}>
						{this.props.children}
					</div>
				</div>
				<div className={`${styles.scrollbar}`} ref={this.scrollbar}>
					<div className={styles.scrollbarTrack}></div>
					<div
						className={styles.scrollbarThumb}
						ref={this.scrollbarThumb}
						onMouseDown={this.mouseDownHandler}
					></div>
				</div>
			</div>
		);
	}
}

export default Scrollable;
