import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import styles from '../styles/Home.module.css';

function Home() {
	const pageAutoFocus = useRef(null);

	useEffect(() => {
		pageAutoFocus.current.focus();
	});

	return (
		<>
			<div className={styles.homePage}>
				<div className={styles.wrapper}>
					<p ref={pageAutoFocus} tabIndex="-1">
						Lorem ipsum dolor sit amet consectetur, adipisicing elit. Alias, fugit eveniet? Fugit soluta
						fugiat quaerat quas minima laboriosam? Aliquid, exercitationem perferendis! Odit odio quos
						repellendus quo facilis aut eos maxime.
					</p>
					<div className={styles.quoteToolLinks}>
						<div>
							<Link to={{ pathname: '/quote-tool', state: { custom: true } }}>Fully Custom Projects</Link>
						</div>
						<div className={styles.spacer}></div>
						<img src="/images/web/home-page-line.svg" alt="" className={styles.lineSeparator} />
						<div className={styles.spacer}></div>
						<div>
							<Link to={{ pathname: '/quote-tool', state: { custom: false } }}>
								Template Based Projects
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Home;
