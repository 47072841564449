import React, { useState, useRef, useEffect, forwardRef } from 'react';
import styles from './Accordion.module.css';

const Accordion = forwardRef((props, ref) => {
	const open = props.open;
	const height = props.height;

	return (
		<div className={styles.section}>
			<button
				className={`${styles.accordion} `}
				aria-expanded={open}
				onClick={() => props.toggleAccordion(props.title, !open)}
			>
				<h3 className={styles.title}>{props.title}</h3>
				<img className={styles.icon} src={open ? '/images/web/minus.svg' : '/images/web/plus.svg'} alt="" />
			</button>
			<div ref={ref} style={{ maxHeight: `${height}` }} className={styles.content}>
				{props.children}
			</div>
		</div>
	);
});

export default Accordion;
