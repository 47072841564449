import React from 'react';

import styles from '../../styles/portfolio/PortfolioItemDesktop.module.css';

/**
 * Note: using index as key in map for img src because:
 * the list and items are static–they are not computed and do not change;
 * the items in the list have no ids;
 * the list is never reordered or filtered.
 */

function PortfolioItemDesktop(props) {
	const goToProject = () => {
		// route user to portfolio-details/projectName
		props.history.push(`/portfolio${props.projectLink}/${props.id}`);

		// pass project id as props of portfolioDetails component
		// console.log(props.id, props.projectLink);
	};

	return (
		<>
			<div className={styles.desktopItem}>
				<div className={styles.content}>
					<div className={styles.contentWrapper}>
						<h1>{props.title}</h1>
						<p>{props.description}</p>
					</div>
					<div className={styles.portfolioLink}>
						<button onClick={goToProject}>view project</button>
					</div>
				</div>
				<div className={styles.collage}>{props.previewSrcs}</div>
			</div>
		</>
	);
}

export default PortfolioItemDesktop;
